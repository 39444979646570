/* Set height to 100% for body and html to enable the background image to cover the whole page: */
/*@import url("https://fonts.googleapis.com/css2?family=Space+Grotesk:wght@300;400;500;600;700&display=swap");*/
/*@import url("https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");*/

/* Font imports */
@font-face { 
  font-family: Delicious; 
  src: url("./Satoshi_Complete/Fonts/OTF/Satoshi-Regular.otf"); 
}
@font-face { 
  font-family: Delicious; 
  font-weight: bold; 
  src: url('./Satoshi_Complete/Fonts/OTF/Satoshi-Bold.otf');
}
@font-face { 
  font-family: Delicious; 
  font-weight: 400; 
  src: url('./Satoshi_Complete/Fonts/OTF/Satoshi-Medium.otf');
}

/* Base styles */
body,
html {
  height: 100%;
  margin: 0;
  padding: 0;
  font-family: Delicious, "Montserrat", sans-serif;
  background-position: center;
  /* Center the image */
  background-repeat: no-repeat;
  /* Do not repeat the image */
  background-size: cover;
}

.cards-container {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 39px;
}

@media screen and (max-width: 768px) {
  .cards-container {
    grid-template-columns: 1fr;
    grid-template-rows: repeat(4, 1fr);
    grid-gap: 40px 0;
  }
}

.bg-faint-white {
  background-color: rgba(255, 255, 255, .55);
  backdrop-filter: blur(5px);
}

@media screen and (max-width: 768px) {
  .bg-faint-white {
    background-color: rgba(255, 255, 255, .55);
    backdrop-filter: blur(5px);
  }
}

.bgVideo {
  /*position: absolute;*/
  z-index: 0;
  /*background: url('https://s3.us-east-1.wasabisys.com/portfolio-site/portfolioSite360-1.mp4') no-repeat;*/
  /*background-size: 100% 100%;*/
  top: 0;
  left: 0; /* fixed to left. Replace it by right if you want.*/
  min-width: 100%;
  height: 100vh;
  /*max-height: 100%;*/
  object-fit: cover;
  /*width: auto;*/
  /*height: auto;*/
}

#interview1 {
  z-index: 0;
  background-size: 100% 100%;
  top: 0;
  left: 0; /* fixed to left. Replace it by right if you want.*/
  min-width: 100%;
  min-height: 100%;
  /*object-fit: cover;*/
}

.overlay {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  background: linear-gradient(
    236deg,
    rgba(108, 97, 68, 0.3),
    rgba(190, 232, 22, 0.3),
    rgba(146, 172, 43, 0.3),
    rgba(223, 147, 41, 0.3)
  );
  background-size: 800% 800%;
  animation: gradientAnimation 19s ease infinite;
}

@keyframes gradientAnimation {
  0% {
    background-position: 99% 0%;
  }
  50% {
    background-position: 2% 100%;
  }
  100% {
    background-position: 99% 0%;
  }
}

.hero {
  left: 50%;
  transform: translateX(-50%);
  z-index: 2;
  position: absolute;
  top: 16rem;
  width: 40%;
}

@media screen and (max-width: 768px) {
  .hero {
    top: 12rem;
    width: 90%;
  }
}

@media screen and (max-width: 768px) {
  .home-title {
    margin-top: -8rem;
  }

  .home-title span {
    font-size: 25px;
  }

  .home-video-controls {
    margin-top: 4.4rem;
  }
}

.hero-video-controls {
  position: absolute;
  bottom: 2.5rem;
  left: 2rem;
  z-index: 10;
  background: none;
  border-radius: 5px;
  padding: 0.25rem;
  height: 2.5rem;
  width: 6rem;
}

.hero-socials {
  position: absolute;
  bottom: 2.5rem;
  right: 1rem;
  z-index: 10;
  background: none;
  border-radius: 5px;
  padding: 0.25rem;
  height: 2.5rem;
  width: 6rem;
}

.hero-center {
  position: absolute;
  bottom: 2.5rem;
  left: 50%;
  transform: translateX(-50%);
  z-index: 10;
}

.press-play-tooltip {
  position: absolute;
  bottom: 3rem;
  left: 0.1rem;
  width: 90%;
  background: rgba(255, 255, 255, 0.1);
  padding: 0.2rem 0.35rem 0.2rem 0.35rem;
  border-radius: 5px;
  backdrop-filter: blur(5px);
  color: white;
  align-items: center;
  z-index: 10;
  transition: all 0.3s ease-in-out;
}

.press-play-tooltip::after {
  content: "";
  position: absolute;
  top: 100%;
  left: 15%;
  margin-left: -5px;
  border-width: 8px;
  border-style: solid;
  transform: rotate(180deg) scaleX(-1);
  border-color: transparent transparent rgba(255, 255, 255, 0.1) transparent;
  backdrop-filter: blur(5px);
}

.glass {
  border-radius: 5px;
  border: #16161649 solid 1px;
  padding: 0.3rem;
}

.ncbceInterview {
  width: 100% !important;
  height: 100% !important;
  margin-top: 20px;
}

.ncbceInterview video {
  border-radius: 7px;
}

.mediaPlayerVideo {
  border-radius: 15px;
  width: 10rem;
  height: 22rem;
  margin-left: 30%;
  transform: translateX(-50%) scale(1.2);
  margin-top: 1rem;
}

.work {
  z-index: 2;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  margin-top: 24%;
}

.work-title {
  text-align: center;
}

.work-content-item {
  transition: all 0.3s ease-in-out;
  padding: 1rem;
}

.work-content-item a {
  text-decoration: none;
  /* color: rgb(26, 26, 26); */
  font-size: 1.2rem;
}

.work-content-item:hover {
  background: rgba(70, 70, 70, 0.7);
  color: white;
  cursor: pointer;
}

.work-collection {
  overflow-y: scroll;
  height: 55vh;
}

.a-loader-title {
  display: none !important;
}

/* Position text in the top-left corner */
.topleft {
  position: fixed;
  top: 1rem;
  left: 1rem;
  width: 80%;
}

.header {
  position: fixed;
  width: 100vw;
  z-index: 3 !important;
}

.dropbtn {
  background: none;
  color: black;
  padding: 16px;
  font-size: 16px;
  border: none;
  cursor: pointer;
  top: -1rem;
  position: relative;
}

.dropdown {
  position: fixed;
  top: 1rem;
  right: 0.5rem;
  display: inline-block;
  transition: all 0.3s ease-in-out;
}
/* Dropdown Content (Hidden by Default) */

.dropdown:hover .dropdown-content {
  display: block;
}
.dropdown-content {
  display: none;
  position: absolute;
  width: 135px;
  background: rgba(255, 255, 255, 0.05);
  backdrop-filter: blur(20px);
  -webkit-backdrop-filter: blur(5px);
  border-radius: 5px;
  z-index: 1;
  right: 0;
  top: 2.5rem;
}

/* Links inside the dropdown */
.dropdown-content a {
  padding: 12px 14px;
  text-decoration: none;
  display: block;
}
.dropdown-content a:first-child:hover {
  border-radius: 4px 4px 0 0;
}

.dropdown-content a:last-child {
  border-bottom: none;
}

/* Change color of dropdown links on hover */
.dropdown-content a:hover {
  transition: all 0.3s ease-in-out;
  background: black;
  color: white;
}

.nav-button {
  position: relative;
  height: 2rem;
  width: 2.1rem;
  background-color: transparent;
  background-image: linear-gradient(to right, currentColor, currentColor);
  background-position: center;
  background-repeat: no-repeat;
  background-size: 100% 5px;
  padding: 0;
  outline: 0;
  border: 0;
  color: black;
  cursor: pointer;
  -webkit-tap-highlight-color: transparent;
  transition: 0.25s cubic-bezier(0.17, 0.67, 0.89, 1.4);
  transition-property: transform;
  will-change: transform;
}

.nav-button::before,
.nav-button::after {
  content: "";
  position: absolute;
  left: 0;
  right: 0;
  display: block;
  height: 5px;
  background: currentColor;
  transition: 0.25s;
  transition-property: transform, top;
  will-change: transform, top;
}

.nav-button::before {
  top: 0;
}

.nav-button::after {
  top: calc(100% - 4px);
}

/* arrow button 3 */
.arrow-button-3:focus {
  transform: rotate(-90deg) translateZ(0);
}

.arrow-button-3:focus::before {
  width: 65%;
  transform: rotate(-45deg) translate3d(-48%, 0, 0);
}

.arrow-button-3:focus::after {
  width: 65%;
  transform: rotate(45deg) translate3d(-48%, 0, 0);
}

.nk-footer {
  color: white;
  position: fixed;
  bottom: 0;
  width: 100%;
  padding-left: 2rem;
  padding-right: 2rem;
  padding-top: 2rem;
  padding-bottom: 2rem;
  max-height: 40px;
}


.footerContent {
  margin-top: -0.5rem;
}

.bottomRight {
  position: fixed;
  bottom: 0;
  right: 1.5rem;
  margin-bottom: 1rem;
  transition: all 0.25s ease-in-out;
  background: rgba(255, 255, 255, 0.1);
  box-shadow: 0 8px 32px 0 rgba(80, 80, 82, 0.2);
  backdrop-filter: blur(5px);
  -webkit-backdrop-filter: blur(5px);
  border-radius: 10px;
  padding: 0.3rem;
  color: black;
}

.blogItem {
  height: 3rem;
  text-align: center;
}

.link {
  z-index: 4;
}

@media screen and (max-width: 1250px) {
  .homeBody {
    font-size: 14px;
    line-height: 1.6rem;
  }
  .homeBodyText {
    width: 90vw;
  }
  .experimentsContent {
    text-align: left;
    width: 90vw;
  }
  .my-form {
    width: 90vw;
  }
}

@media screen and (max-width: 500px) {
  small {
    display: block !important;
  }
}

ion-icon:hover {
  cursor: pointer;
}

ion-icon {
  transition: all 0.3s ease-in-out;
}

ion-icon[color="white"] {
  color: white;
}



.content {
  position: absolute;
  z-index: 1;
  color: black;
  left: 50%;
  transform: translateX(-50%);
  top: 8rem;
  padding: 1rem;
}

.content-darker {
  background: rgba(0, 0, 0, 0.55);
  width: 30%;
  top: 10rem;
}

.contentButtons {
  justify-content: space-around;
  margin-top: 1.5rem;
  margin-bottom: 1.5rem;
}

.contentButton {
  position: relative;
  z-index: 2;
  color: black;
  border: none;
  background: rgba(255, 255, 255, 0.15);
  text-decoration: none;
  /*font-style: none;*/
  padding: 15px;
  margin-right: 15px;
}

.contactButton:hover {
  text-decoration: none;
  color: black;
}

.blog {
  position: absolute;
  width: 70%;
  height: 100%;
  z-index: 2;
  left: 50%;
  transform: translateX(-50%);
  top: 5rem;
}

.blog > a {
  text-decoration: none;
  color: black;
  font-size: 1rem;
  padding: 1rem;
  margin: 10px;
}

.muteButton {
  position: absolute;
  z-index: 1;
  color: white;
  margin-top: 50%;
  transform: translateY(-50%);
  padding-top: 3rem;
  right: 7px;
}

@media screen and (max-width: 1000px) {
  .muteButton {
    margin-top: 550px;
    transform: translateY(0%);
  }
}

form input {
  width: 50%;
  padding: 0.5rem;
  margin-bottom: 0.5rem;
  border-radius: 5px;
  border: 2px solid #ccc;
  background: none;
}

form textarea {
  width: 50%;
  padding: 0.5rem;
  margin-bottom: 0.5rem;
  border-radius: 5px;
  border: 2px solid #ccc;
  background: none;
}

form button {
  width: 50%;
  padding: 0.5rem;
  margin-bottom: 0.5rem;
  border-radius: 5px;
  border: 1px solid #ccc;
  transition: all 0.3s ease-in-out;
  background: rgba(0, 0, 0, 0.9);
  color: white;
}

form button:hover {
  cursor: pointer;
  background: rgba(0, 0, 0, 0.7);
}

.secretForm input {
  width: 60%;
  margin-left: 50%;
  transform: translateX(-50%);
}

.secretForm button {
  width: 60%;
  margin-left: 50%;
  transform: translateX(-50%);
}

.secretConnectBtn {
  position: absolute;
  z-index: 10;
  text-align: center;
  border-radius: 10px;
  color: white;
  top: 20rem;
  left: 50%;
  transform: translateX(-50%);
  background: black;
  padding: 15px;
  font-size: 1.25rem;
  /* text-transform: uppercase; */
}

.markerImg {
  width: 60%;
  z-index: 10;
  opacity: 1;
  visibility: visible;
  display: block;
}

.mediaPlayer {
  overflow-y: scroll;
  height: 90vh;
}

@keyframes fadeUpAnimation {
  from {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.fade-up {
  animation: fadeUpAnimation 0.8s ease-out forwards;
}

.delay-200 {
  animation-delay: 200ms;
}

.delay-400 {
  animation-delay: 400ms;
}
